jQuery(function($) {
  var $smooth  = $('a[href^="#"]');
  // #で始まるアンカーをクリックした場合に処理
  $smooth.on('click', function() {
    // スクロールの速度
    var speed = 800;// ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href === '#top' || href === '#' || href === '' ? 'html' : href);
    // var nav = $("#nav").height();
    // var header =  $('#header').outerHeight();
    var header =  0;
    // 移動先を数値で取得
    var position = target.offset().top - header;
    // スムーススクロール
    if( !$('body html').is(':animated') ) {
      $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
    }
    return false;
  });

  var $onOff = $('.on_off'),
      $noChange = "none";
  $onOff.hover(function() {
    $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
  },
  function() {
    if (!$(this).hasClass($noChange)) {
      $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
    }
  });

  var $backBtn = $('#back-btn');
    $backBtn.on('click', function() {
    history.back();
  });

  //アコーディオン
  $(".q-faq").on("click", function() {
    $(this).next('.a-faq').slideToggle();
    $(this).toggleClass('active');
  });

  $(window).on("load scroll",function(){
      var
      $elmTop  = ($("#fv").offset().top)+(900)
      $formTop = ($("#faq").offset().top)+(700)
      $thisTop = $(this).scrollTop()
      if( $elmTop > $thisTop || $formTop < $thisTop ) {
          $("#fixed").fadeOut()
      } else {
          $("#fixed").fadeIn()
      }
  });

});